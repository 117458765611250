
import * as cloudinaryRuntime$xwExV7ANjz from '/app/node_modules/.pnpm/@nuxt+image@1.2.0_rollup@4.9.0/node_modules/@nuxt/image/dist/runtime/providers/cloudinary'
import * as ipxRuntime$g8STRQDPBZ from '/app/node_modules/.pnpm/@nuxt+image@1.2.0_rollup@4.9.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudinary']: { provider: cloudinaryRuntime$xwExV7ANjz, defaults: {"baseURL":"https://res.cloudinary.com/dfdjkdyit/image/fetch/","modifiers":{"quality":"auto:best"}} },
  ['ipx']: { provider: ipxRuntime$g8STRQDPBZ, defaults: {} }
}
        